.GIFpage {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  max-width: 1600px;
  min-width: 400px;
  opacity: 0.7;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: relative;
}

@media (max-width: 1300px) {
  .GIFpage {
    width: 1200px;
  }
}

@media (max-width: 900px) {
  .GIFpage {
    width: 800px;
  }
}

@media (max-width: 500px) {
  .GIFpage {
    width: 400px;
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: indigo;
  z-index: 100;
  background: rgba(255, 100, 100, 1);
  margin: auto auto;
  width: 300px;
  height: 300px;
  position: fixed; 
  border-radius: 10px;
  left: 50px;
  right: 100px;
  top: 100px;
  bottom: 100px;
  border: 10px solid indigo;
}

.balloons {
  position: fixed;
  left: -100px;
  top: 100px;
  right: 200px;
  bottom: 100px;
  animation: balloonsMove 5s infinite;
}

@keyframes balloonsMove {
  0% {
    top: 100vh;
  }

  100% {
    top: -100vh;
  }
}